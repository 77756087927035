<template>
    <div class="notfound">
        <h1>404</h1>
        <h3>抱歉，你访问的页面不存在</h3>
        <el-button type="primary" v-on:click="back">返回上一级</el-button>
    </div>
</template>

<script>
    import router from "../../router";

    export default {
        name: "404",
        methods:{
            back(){
                router.push('/dashboard');
            }
        }
    }
</script>

<style scoped>
    .notfound{
        padding-top: 100px;
        text-align: center;
    }
    h1{
        font-size: 50px;
    }
</style>